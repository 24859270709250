var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "medical-record-management" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.tabsClick },
          model: {
            value: _vm.ifRecord,
            callback: function($$v) {
              _vm.ifRecord = $$v
            },
            expression: "ifRecord"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "已建档", name: "1" } },
            [
              _c("EstablishedArchives", {
                ref: "establishedArchivesRef",
                on: { name: _vm.activeNameSub, loading: _vm.loading }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "未建档", name: "0" } },
            [
              _c("UnestablishedArchives", {
                ref: "unEstablishedArchivesRef",
                on: { name: _vm.activeNameSub, loading: _vm.loading }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }