<template>
  <div class="medical-record-management">
    <el-form class="zwx-form" key="staffTdPeRecordForm" :model="staffTdPeRecordForm" ref="staffTdPeRecordForm"
      label-position="right" @submit.native.prevent>
      <div class="condition-row">
        <el-form-item label="行政区划：" label-width="100px">
          <zwx-select-area class="cascader-area-biz-zone" ref="areaIdArea" :zoneCode12From="zoneCode12From"
            @loadOver="areaIdLoadOver()" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :disabled="false"
            :clearable="false" />
        </el-form-item>
        <el-form-item label="单位名称：" label-width="100px">
          <el-input class="zwx-input " placeholder="请输入" style="width: 200px !important;"
            v-model.trim="staffTdPeRecordForm.employerNameLike" maxlength="20" clearable />
        </el-form-item>
        <el-form-item label="员工姓名：" label-width="70px">
          <el-input class="zwx-input " placeholder="请输入" style="width: 200px !important;"
                    v-model.trim="staffTdPeRecordForm.staffNameLike" maxlength="20" clearable />
        </el-form-item>
      </div>
      <div class="condition-row">
        <el-form-item label="证件号码：" label-width="100px">
          <el-input class="zwx-input " placeholder="请输入完整证件号码" style="width: 200px !important;"
                    v-model.trim="staffTdPeRecordForm.cardNo" maxlength="20" clearable />
        </el-form-item>
        <el-form-item label="最新体检日期：" label-width="100px">
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;"
            v-model="staffTdPeRecordForm.lastPeDateBegin" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            :picker-options="$validate.noBigDate($data['staffTdPeRecordForm'], 'lastPeDateEnd', true)"
            placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;"
            v-model="staffTdPeRecordForm.lastPeDateEnd" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
            :picker-options="$validate.noSmallDate($data['staffTdPeRecordForm'], 'lastPeDateBegin', true)"
            placeholder="结束日期" />
        </el-form-item>
        <div style="clear: both" />
      </div>
      <div class="condition-row">
        <el-form-item label="在职状态：" prop="dutyStatus" label-width="100px" >
          <el-select class="zwx-select processMode-select" popper-class="zwx-select-popper"
                     v-model="staffTdPeRecordForm.dutyStatus" placeholder="请选择" :filterable="true" :clearable="true">
            <el-option label="在职" :value="1"></el-option>
            <el-option label="离职" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search"
        @click="queryStaffPeRecordList(1)">查询</el-button>
    </div>
    <el-table class="zwx-table" style="width: 100%" row-key="rid" key="medicalRecordTable"
      :data="staffTdPeRecordFormExp.tableList" stripe border>
      <el-table-column prop="bizName" label="行政区划" width="180" header-align="center" align="left"></el-table-column>
      <el-table-column prop="employerName" label="单位名称" width="260" header-align="center" align="left"></el-table-column>
      <el-table-column prop="staffName" label="员工姓名" width="120" header-align="center" align="left"></el-table-column>
      <el-table-column prop="showCardNo" label="证件号码" width="260" header-align="center" align="left"></el-table-column>
      <el-table-column prop="lastPeType" label="在职状态" width="100" header-align="center" align="left">
        <template slot-scope="scope">
          <div>{{ scope.row.dutyStatus === 1 ? '在职' : scope.row.dutyStatus === 2 ? '离职' : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="lastPeDate" label="最近体检日期" width="120" header-align="center" align="center"
        :formatter="dateFormatter"></el-table-column>
      <el-table-column prop="lastPeType" label="最近体检类型" width="140" header-align="center" align="left">
        <template slot-scope="scope">
          <div>{{ scope.row.lastPeType === 1 ? '职业健康体检' : scope.row.lastPeType === 2 ? '放射人员健康检查' : scope.row.lastPeType
            ===
            3 ? '福利体检' : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="peNum" label="总体检次数" width="120" header-align="center" align="left"></el-table-column>
      <el-table-column label="操作" header-align="left" align="left" fixed="right" :min-width="160">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right"
            @click="recordDetail(scope.row)">查看</el-button>
          <!-- <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" @click="delMedicalRecord(scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="staffTdPeRecordForm.currentPage"
      :pageSize="staffTdPeRecordFormExp.pageSize" :total="staffTdPeRecordFormExp.total"
      @currentChange="queryStaffPeRecordList" />
  </div>
</template>

<script>
export default {
  name: 'EstablishedArchives',
  data() {
    return {
      users: this.$store.state.users,
      api: this.$store.state.api,
      zoneCode12From: '',
      staffTdPeRecordForm: {
        currentPage: 1,
        cardNo: null, //证件号码
        lastPeDateBegin: '', //开始日期
        lastPeDateEnd: '', //结束日期
        staffNameLike: null, //员工姓名
        bizZone: '',//行政区划
        employerNameLike: null,//用人单位名称
        dutyStatus: '',//在职状态
        ifRecord: '',//是否建档
      },
      staffTdPeRecordFormExp: {
        employerUuid: '', //单位Id
        tableList: [],
        pageSize: 18,
        total: 0,
      },
    }
  },
  computed: {},
  created() {
    this.zoneCode12From = this.users.ifSuperAdmin ? '000000000000' : this.users.fkByUnitId.fkByZoneId.zoneCode12
  },
  mounted() {
    this.$refs.areaIdArea.initSearch()
  },
  methods: {

    areaIdLoadOver() {
      this.queryStaffPeRecordList(this.staffTdPeRecordForm.currentPage)
    },
    /**
     * 查询员工体检档案列表
     */
    queryStaffPeRecordList(currentPage) {
      this.staffTdPeRecordForm.currentPage = currentPage
      this.staffTdPeRecordForm.bizZone = this.$refs.areaIdArea.getChooseArea().zoneCode12
      let data = {
        ...this.staffTdPeRecordForm,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getStaffTdPeRecordList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.staffTdPeRecordFormExp.tableList = data.tdStaffInfoExpList
            this.staffTdPeRecordFormExp.total = data.total
            this.staffTdPeRecordFormExp.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    /**
     * 查看
     */
    recordDetail(row) {
      this.$router.push({
        name: 'StaffPeRecordDetailPage',
        params: { type: 'view', employerUuid: row.employerUuid, staffId: row.rid, staffUid: row.uuid },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.medical-record-management {

  /deep/ .cascader-area-biz-zone .zwx-input {
    width: 200px !important;
  }

  .self-adapting-condition {
    max-width: unset !important;
  }

  /deep/ .el-dialog {
    width: 676px !important;
    min-width: 676px !important;
  }
}
</style>

<style lang="less"></style>
