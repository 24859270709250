import { render, staticRenderFns } from "./UnEstablishedArchives.vue?vue&type=template&id=2088ea22&scoped=true"
import script from "./UnEstablishedArchives.vue?vue&type=script&lang=js"
export * from "./UnEstablishedArchives.vue?vue&type=script&lang=js"
import style0 from "./UnEstablishedArchives.vue?vue&type=style&index=0&id=2088ea22&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2088ea22",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\product-yangzhou\\view\\ms-vue-occupational-health\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2088ea22')) {
      api.createRecord('2088ea22', component.options)
    } else {
      api.reload('2088ea22', component.options)
    }
    module.hot.accept("./UnEstablishedArchives.vue?vue&type=template&id=2088ea22&scoped=true", function () {
      api.rerender('2088ea22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/health_surveillance/management/staff_record/components/UnEstablishedArchives.vue"
export default component.exports