<template>
  <div class="main-page">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row ">
        <el-form-item label="类别：" label-width="42px" prop="pointTypes">
          <!-- <cascader-options-multi ref="mscCascader" v-model="msForm.pointTypes" width="160" collapsetags :options="simpleCodeList" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" codeLevelNo="codeLevelNo"></cascader-options-multi> -->
          <cascader-options-multi ref="mscCascader" v-model="msForm.pointTypes" :options="simpleCodeList" dataNameFiled="codeName" dataCodeFiled="codeNo" dataUpCodeFiled="children" codeLevelNo="codeLevelNo" width="260px" :showFullTag="false" :collapsetags="true" v-on:propEvent="propEvent"></cascader-options-multi>
        </el-form-item>
        <el-form-item label="题型：" label-width="42px">
          <el-select class="zwx-select query-select" popper-class="zwx-select-popper"  v-model="msForm.topicTypeArr" multiple placeholder="请选择" collapse-tags>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题：" label-width="42px">
          <el-input style="width: 300px !important" class="zwx-input" v-model.trim="msForm.topicContent" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="状态：" label-width="42px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnableArr">
            <el-checkbox class="zwx-checkbox" :label="1">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="query()">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addEmployerInvestigation">添加</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%" row-key="rid+'-only'" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="pointTypeName" label="类别" width="200" header-align="center" align="left" />
      <el-table-column prop="topicType" label="题型" width="100" header-align="center" align="center" :formatter="topicTypeFormatter" />
      <el-table-column prop="topicContent" label="标题" width="450" header-align="center" align="left" />
      <el-table-column prop="ifEnable" label="状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-normal">启用</div>
          </template>
          <template v-if="!scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-underway">停用</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="$system.calMinWidth({ wordNum: 3, arrowNum: 1, btnNum: 3 })" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" @click="editEmployerInvestigation(scope.row)" icon="el-icon-arrow-right">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable === false" @click="enableOrDisable(scope.row)">启用</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" v-if="scope.row.ifEnable === true" @click="enableOrDisable(scope.row)" style="color:rgb(231, 43, 0) !important;">停用</el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" v-if="scope.row.ifEnable === false" type="danger" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', deleteTrainingTopic)"><span style="color: #DD3838;">删除</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <base-pagination class="normal-pagination" :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />
  </div>
</template>

<script>
export default {
  name: 'TopicManage',
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        topicTypeArr: [],
        topicContent: '',
        pointTypes: '',
        ifEnableArr: [1],
      },
      currentPage: 1,
      pageSize: 18,
      total: 0,
      dataList: [],
      pointTypeList: [], //培训类型平铺数据源
      simpleCodeList: [], // 培训类型层级
      options: [
        {
          value: 1,
          label: '单选',
        },
        {
          value: 2,
          label: '多选',
        },
        {
          value: 3,
          label: '判断题',
        },
      ],
    }
  },
  created() {},
  mounted() {
    this.query()
    // 获取培训类型数据源
    this.getPointTypeList()
  },
  activated() {
    if (this.$route.params.fresh) this.currentChange(this.currentPage)
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 培训类型数据源
     */
    getPointTypeList() {
      var list = []
      this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('4020', data => {
        list = data['4020']
        this.pointTypeList = list
        if (this.$zwxBase.verifyIsNotBlank(list)) {
          this.simpleCodeList = this.manyListFul(list, '')
        }
      })
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    //组件返回监听
    propEvent: function(somedata) {
      this.$set(this.msForm, 'pointTypes', somedata)
    },
    /**
     * 查询
     */
    query() {
      this.currentPage = 1
      this.currentChange(1)
    },
    /**
     * 分页查询
     */
    currentChange(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      let data = {
        topicContent: this.msForm.topicContent,
        topicTypeArr: this.$zwxBase.verifyIsNotBlank(this.msForm.topicTypeArr) ? this.msForm.topicTypeArr.join(',') : null,
        pointTypes: this.msForm.pointTypes,
        ifEnableArr: this.$zwxBase.verifyIsNotBlank(this.msForm.ifEnableArr) ? this.msForm.ifEnableArr.join(',') : null,
        currentPage: currentPage,
      }
      this.$system.get(
        this.api + '/training/platform/getTrainingTopicList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.dataList = data.trainingTopicList
            this.total = data.trainingTopicCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 启用/停用
     */
    enableOrDisable(row) {
      var mess = row.ifEnable ? '停用' : '启用'
      this.$system.msgbox('', '提示', '是否确定' + mess + '？', '确定', '取消', () => {
        let data = {
          uuid: row.uuid,
          ifEnable: row.ifEnable ? false : true,
        }
        this.$emit('loading', true)
        this.$system.post(
          this.api + '/training/platform/enableOrDisableTrainingTopic-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type == '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.currentChange(this.currentPage)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          data => {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        )
      })
    },

    /**
     * 删除
     */
    deleteTrainingTopic(row) {
      let data = {
        uuid: row.uuid,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/training/platform/deleteTrainingTopic-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.query()
            this.$emit('loading', false)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //添加
    addEmployerInvestigation(row) {
      this.$router.push({ name: 'TopicManageEdit', params: { type: 'add' } })
    },
    //编辑
    editEmployerInvestigation(row) {
      this.$router.push({ name: 'TopicManageEdit', params: { uuid: row.uuid, type: 'edit' } })
    },

    /**
     * 题目类型 数据处理
     */
    pointTypeFormatter(row, column, cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '40200001') {
          return '法律法规'
        } else if (cellValue == '40200002') {
          return '规章制度'
        } else if (cellValue == '40200003') {
          return '个人安全防护'
        } else if (cellValue == '40200004') {
          return '危害因素辨识'
        } else if (cellValue == '40200005') {
          return '防护用品配备'
        }
      } else {
        return ''
      }
    },
    /**
     * 题目类型 数据处理
     */
    topicTypeFormatter(row, column, cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        if (cellValue == '1') {
          return '单选'
        } else if (cellValue == '2') {
          return '多选'
        } else if (cellValue == '3') {
          return '判断'
        }
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .query-select > .el-input.el-input--suffix > .el-input__inner {
  width: 250px !important;
}
/deep/ .cascader-point .el-input__inner {
  height: 32px !important;
}
</style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 300px;
}
</style>
