<template>
  <div class="medical-record-management">
    <el-tabs v-model="ifRecord" @tab-click="tabsClick">
      <el-tab-pane :label="'已建档'" name="1">
        <EstablishedArchives ref="establishedArchivesRef" @name="activeNameSub" @loading="loading"></EstablishedArchives>
      </el-tab-pane>
      <el-tab-pane :label="'未建档'" name="0">
        <UnestablishedArchives ref="unEstablishedArchivesRef"
          @name="activeNameSub" @loading="loading"></UnestablishedArchives>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import EstablishedArchives from './components/EstablishedArchives.vue';
import UnestablishedArchives from './components/UnEstablishedArchives.vue';
export default {
  name: 'StaffTdPeRecordManagement',
  components: { EstablishedArchives, UnestablishedArchives },
  data() {
    return {
      users: this.$store.state.users,
      api: this.$store.state.api,
      postSimpleCodeList: [],
      ifRecord: '1',
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.$refs.establishedArchivesRef.queryStaffPeRecordList(1)
  },
  methods: {
    loading(bl){
      this.$emit('loading',bl)
    },
    tabsClick(tab, event) {
      if (tab.name == '0') {
        this.$refs.unEstablishedArchivesRef.queryStaffPeRecordList(1)
      } else if (tab.name == '1') {
        this.$refs.establishedArchivesRef.queryStaffPeRecordList(1)
      }
    },

    activeNameSub(value) {
      this.activeName = value
      if (value == '0') {
        this.$refs.unEstablishedArchivesRef.queryStaffPeRecordList(1)
      }
      if (value == '1') {
        this.$refs.establishedArchivesRef.queryStaffPeRecordList(1)
      }
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: none;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 12px !important;
}

.medical-record-management {

  /deep/ .self-adapting-condition {
    max-width: 1360px !important;
  }

  /deep/ .el-tabs__header {
    margin-bottom: 0 !important;
  }

  /deep/ .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px !important;
  }
}
</style>

<style lang="less"></style>
