var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "medical-record-management" },
    [
      _c(
        "el-form",
        {
          key: "staffTdPeRecordForm",
          ref: "staffTdPeRecordForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.staffTdPeRecordForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "condition-row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "行政区划：", "label-width": "100px" } },
                [
                  _c("zwx-select-area", {
                    ref: "areaIdArea",
                    staticClass: "cascader-area-biz-zone",
                    attrs: {
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: false,
                      disabled: false,
                      clearable: false
                    },
                    on: {
                      loadOver: function($event) {
                        return _vm.areaIdLoadOver()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单位名称：", "label-width": "100px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "20",
                      clearable: ""
                    },
                    model: {
                      value: _vm.staffTdPeRecordForm.employerNameLike,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffTdPeRecordForm,
                          "employerNameLike",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "staffTdPeRecordForm.employerNameLike"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "员工姓名：", "label-width": "70px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "20",
                      clearable: ""
                    },
                    model: {
                      value: _vm.staffTdPeRecordForm.staffNameLike,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffTdPeRecordForm,
                          "staffNameLike",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "staffTdPeRecordForm.staffNameLike"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "证件号码：", "label-width": "100px" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入完整证件号码",
                      maxlength: "20",
                      clearable: ""
                    },
                    model: {
                      value: _vm.staffTdPeRecordForm.cardNo,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffTdPeRecordForm,
                          "cardNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "staffTdPeRecordForm.cardNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最新体检日期：", "label-width": "100px" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.$validate.noBigDate(
                        _vm.$data["staffTdPeRecordForm"],
                        "lastPeDateEnd",
                        true
                      ),
                      placeholder: "开始日期"
                    },
                    model: {
                      value: _vm.staffTdPeRecordForm.lastPeDateBegin,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffTdPeRecordForm,
                          "lastPeDateBegin",
                          $$v
                        )
                      },
                      expression: "staffTdPeRecordForm.lastPeDateBegin"
                    }
                  }),
                  _vm._v(" - "),
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.$validate.noSmallDate(
                        _vm.$data["staffTdPeRecordForm"],
                        "lastPeDateBegin",
                        true
                      ),
                      placeholder: "结束日期"
                    },
                    model: {
                      value: _vm.staffTdPeRecordForm.lastPeDateEnd,
                      callback: function($$v) {
                        _vm.$set(_vm.staffTdPeRecordForm, "lastPeDateEnd", $$v)
                      },
                      expression: "staffTdPeRecordForm.lastPeDateEnd"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticStyle: { clear: "both" } })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "condition-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "在职状态：",
                    prop: "dutyStatus",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select processMode-select",
                      attrs: {
                        "popper-class": "zwx-select-popper",
                        placeholder: "请选择",
                        filterable: true,
                        clearable: true
                      },
                      model: {
                        value: _vm.staffTdPeRecordForm.dutyStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.staffTdPeRecordForm, "dutyStatus", $$v)
                        },
                        expression: "staffTdPeRecordForm.dutyStatus"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "在职", value: 1 } }),
                      _c("el-option", { attrs: { label: "离职", value: 2 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticStyle: { clear: "both" } })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "independent-button-row" },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.queryStaffPeRecordList(1)
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          key: "medicalRecordTable",
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid",
            data: _vm.staffTdPeRecordFormExp.tableList,
            stripe: "",
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "bizName",
              label: "行政区划",
              width: "180",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "employerName",
              label: "单位名称",
              width: "260",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "staffName",
              label: "员工姓名",
              width: "120",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "showCardNo",
              label: "证件号码",
              width: "260",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lastPeType",
              label: "在职状态",
              width: "100",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          scope.row.dutyStatus === 1
                            ? "在职"
                            : scope.row.dutyStatus === 2
                            ? "离职"
                            : ""
                        ) + " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lastPeDate",
              label: "最近体检日期",
              width: "120",
              "header-align": "center",
              align: "center",
              formatter: _vm.dateFormatter
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lastPeType",
              label: "最近体检类型",
              width: "140",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          scope.row.lastPeType === 1
                            ? "职业健康体检"
                            : scope.row.lastPeType === 2
                            ? "放射人员健康检查"
                            : scope.row.lastPeType === 3
                            ? "福利体检"
                            : ""
                        ) + " "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "peNum",
              label: "总体检次数",
              width: "120",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "header-align": "left",
              align: "left",
              fixed: "right",
              "min-width": 160
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text", icon: "el-icon-arrow-right" },
                        on: {
                          click: function($event) {
                            return _vm.recordDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        staticClass: "normal-pagination",
        attrs: {
          parentPage: _vm.staffTdPeRecordForm.currentPage,
          pageSize: _vm.staffTdPeRecordFormExp.pageSize,
          total: _vm.staffTdPeRecordFormExp.total
        },
        on: { currentChange: _vm.queryStaffPeRecordList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }